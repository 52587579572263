<template>
    <div class="navbar">
      <div class="betweenCenter flow gap">
        <div class="logo">
          <h4 @click="redirectTo('Principal')">Lorenzini</h4>
        </div>
        <div class="contentNav leftCenter gap">
          <div class="nav-item dropdown">
            <a href="#" class="nav-link dropdown-toggle">Catálogo</a>
            <ul class="dropdown-menu ">
              <li><a class="dropdown-item" href="#">Seguridad</a></li>
              <li><a class="dropdown-item" href="#">Tránsito</a></li>
              <li><a class="dropdown-item" href="#">Covid 19</a></li>
            </ul>
          </div>
          <div class="nav-item dropdown">
            <a href="#" class="nav-link dropdown-toggle">Personalizado</a>
            <ul class="dropdown-menu ">
              <li><a class="dropdown-item" @click="$router.push('Personalizador')">Crear letrero personalizado</a></li>
              <li><a class="dropdown-item" href="#">Pictogramas</a></li>
              <li><a class="dropdown-item" href="#">Historial de compras</a></li>
            </ul>
          </div>
          <div class="nav-item dropdown">
            <a href="#" class="nav-link dropdown-toggle">Más Información</a>
            <ul class="dropdown-menu ">
              <li><a class="dropdown-item" href="#">Información sobre envíos</a></li>
              <li><a class="dropdown-item" href="#">Materiales</a></li>
              <li><a class="dropdown-item" href="#">Contáctanos</a></li>
            </ul>
          </div>
        </div>
        <div class="rightCenter">
          <div class="input-group">
            <input type="text" class="form-control inputBusqueda" placeholder="Buscar" aria-label="Recipient's username" aria-describedby="button-addon2">
            <button class="btn btnBusqueda" type="button" id="button-addon2"><img src="../../public/search.svg" ></button>
          </div>
          <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal" >
            <img src="../../public/user.svg" >
          </button>
        </div>
      </div>


        <!-- <div class="nav-buttons nav-left"> -->
        <!-- Aquí puedes agregar el contenido del navbar -->
        <!-- </div> -->
      <!-- <div class="nav-buttons nav-rigth"> -->
        <!-- Aquí puedes agregar el contenido del navbar -->

      <!-- </div> -->
    </div>
     <!-- Modal -->
     <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Ingreso</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Correo electrónico</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
              </div>
                    <label for="inputPassword5" class="form-label">Contraseña</label>
                    <input type="password" id="inputPassword5" class="form-control" aria-labelledby="passwordHelpBlock">
              </div>
            <div class="modal-footer w100" >
                <button type="button" data-size="modal" class="btn btn-dark">Ingresar</button>
            </div>
            </div>
        </div>
        </div>
    
    <div class="content">
      <router-view ></router-view>
    </div>
    <footer class="centrar">
      © 2023
    </footer>
</template>

<script>
export default {
  name: 'Inicio',
  methods: {
    redirectTo(pagina){
        this.$router.push(pagina);
    }
  },
  
}
</script>

<style>
.logo{
  cursor: pointer;
}
.dropdown-item{
  cursor: pointer;
}
</style>