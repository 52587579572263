import { createRouter, createWebHistory } from 'vue-router';
import Principal from './views/Principal.vue';
import Inicio from './views/Inicio.vue';
import Personalizador from './views/Personalizador.vue';

const routes = [
  { path: '/', redirect: '/inicio',  component: Inicio },
  { path: '/inicio', name: 'Inicio', component: Inicio, children: [
    {path: '/principal', name: 'Principal', component: Principal},
    {path: '/personalizador', name: 'Personalizador', component: Personalizador},
  ]}
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
