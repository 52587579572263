<template>
    <div class="container">
        <div class="container__title">
            <h2>Selecciona tu producto y personalizalo como quieras</h2>
            <p>Con el <a>personalizador</a> arma tu propio letrero</p>
            <p>Contamos con diversidad de productos en <a>Seguridad</a>, <a>Tránsito</a> y <a>más</a></p>
        </div>
        <div class="contenidoPrincipal center">
            <div class="containerCards center">
                <div class="card1">
                    <div class="card__imag">
                        <img src="../assets/personalizador.png" class="" alt="Personalizador" width="200" height="200" >
                    </div>
                <div class="card__body">
                    <h5 class="card__title">Personalizador</h5>
                    <p class="card__text">Elige tu producto y personalizalo fácil y rápido</p>
                </div>
                <div class="card__block">
                    <a href="#" class="card__bton center" @click="$router.push('Personalizador')">
                        <p>Ir a cotizar</p>
                    </a>
                </div>
            </div>
            <div class="card1">
                <div class="card__imag">
                    <img src="../assets/seguridad.png" class="" alt="Personalizador" width="200" height="200" >
                </div>
                <div class="card__body">
                    <h5 class="card__title">Seguridad</h5>
                    <p class="card__text">Catálogo de seguridad</p>
                </div>
                <div class="card__block">
                    <a href="#" class="card__bton center">
                        <p>Ir al catálogo</p>
                    </a>
                </div>
            </div>
            <div class="card1">
                <div class="card__imag">
                    <img src="../assets/transito.png" class="" alt="Personalizador" width="200" height="200" >
                </div>
                <div class="card__body">
                    <h5 class="card__title">Tránsito</h5>
                    <p class="card__text">Catálogo de tránsito</p>
                </div>
                <div class="card__block">
                    <a href="#" class="card__bton center">
                        <p>Ir al catálogo</p>
                    </a>
                </div>
            </div>
            <div class="card1">
                <div class="card__imag">
                    <img src="../assets/historial.png" class="" alt="Personalizador" width="200" height="200" >
                </div>
                <div class="card__body">
                    <h5 class="card__title">Historial</h5>
                    <p class="card__text">Consulta tu historial de compra</p>
                </div>
                <div class="card__block">
                    <a href="#" class="card__bton center">
                        <p>Consultar</p>
                    </a>
                </div>
            </div>
            
            </div>
        </div>
    </div>
     </template>

<script>
export default {
  name: 'Principal'
}
</script>

<style scoped>
.container{max-width:var(--maxWidth)!important;}
.contenidoPrincipal{margin-block:40px;}
.containerCards {gap:var(--gap)!important;}
.card1{width:var(--w25); min-width:250px; border:none!important; background:#ffffff; box-shadow:-2px 6px 15px -1px rgba(0 0 0/10%); border-radius:6px; overflow:hidden;}
.card__imag{width:100%; height:190px; padding:20px;}
.card__imag img{max-width:150px; width:100%; height:auto; object-fit:cover;}
.card__body{width:100%; height:110px; margin-top:15px; padding:0px 15px;}
.card__title{font-size:20px; color:var(--negro);}
.card__text{font-size:15px; color:#666666;}
.card__block{width:100%; height:50px; background:#333333;}
.card__bton{width:100%; height:100%; text-decoration:none; color:#ffffff;}
.card__bton:hover{background:var(--azul);}
.card__bton p{margin-bottom:0;}
</style>