<template>
    <div class="container">
        <div class="personalizador__container">
                <div class="personalizador__div">
                    <img src="../assets/modelo.png" class="card-img-top" alt="Personalizador" width="200" height="200" >
                </div>
                <div class="personalizador__div">
                    <label  for="">Este apartado no afecta el precio</label>
                    <form class="personalizador__settings">
                        <div class="btn-group w100" role="group" aria-label="Basic example">
                        <button type="button" id="tab1" class="btn" @click="tabSelected = 1;changeTab()">Normativa</button>
                        <button type="button" id="tab2" class="btn" @click="tabSelected = 2;changeTab()">Encabezado</button>
                        <button type="button" id="tab3" class="btn" @click="tabSelected = 3;changeTab()">Cuerpo</button>
                        </div>
                        <div class="form__container" v-if="tabSelected == 1">
                            <div class="div__input w100 ">
                                <label for="">Norma que cumplir</label>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">Ninguna</option>
                                        <option value="">Norma Europea (ISO)</option>
                                        <option value="">Norma Americana (ANSI)</option>
                                    </select>
                                </div>
                            </div>
                            <div class="div__input">
                                <label for="">Color de fondo</label>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">Blanco</option>
                                        <option value="">Rojo</option>
                                        <option value="">Amarillo</option>
                                        <option value="">Azul</option>
                                        <option value="">Verde</option>
                                        <option value="">Negro</option>
                                    </select>
                                </div>
                            </div>
                            <div class="div__input">
                                <label for="">Ribete</label>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">Ribete Fino</option>
                                        <option value="">Ribete Medio</option>
                                        <option value="">Ribete Grueso</option>
                                        <option value="">Sin Rebete</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form__container" v-if="tabSelected == 2">
                            <div class="div__input w100 ">
                                <label for="">Forma</label>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">Ninguna</option>
                                        <option value="">Recto</option>
                                        <option value="">Ovalado</option>
                                    </select>
                                </div>
                            </div>
                            <div class="div__input">
                                <label for="">Color</label>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">Blanco</option>
                                        <option value="">Rojo</option>
                                        <option value="">Amarillo</option>
                                        <option value="">Azul</option>
                                        <option value="">Verde</option>
                                        <option value="">Negro</option>
                                    </select>
                                </div>
                            </div>
                            <div class="div__input">
                                <label for="">Tamaño</label>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">Pequeño</option>
                                        <option value="">Mediano</option>
                                        <option value="">Grande</option>
                                    </select>
                                </div>
                            </div>
                            <div class="div__input">
                                <label for="">Texto</label>
                                <div class="form__input">
                                    <input class="w90" style="margin-bottom: 5px;" type="text" placeholder="TEXTO" name="" id="">
                                    <input class="w90" type="text" placeholder="PERSONALIZADO" name="" id="">
                                </div>
                            </div>
                        </div>
                        <div class="form__container" v-if="tabSelected == 3">
                            <div class="div__input w100 ">
                                <label for="">Pictograma</label>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">ATEN5010</option>
                                        <option value="">Cambiar</option>
                                        <option value="">Ninguno</option>
                                    </select>
                                </div>
                            </div>
                            <div class="div__input">
                                <label for="">Texto</label>
                                <div class="form__input">
                                    <input class="w90" style="margin-bottom: 5px;" type="text" placeholder="INGRESE" name="" id="">
                                    <input class="w90" style="margin-bottom: 5px;" type="text" placeholder="SU" name="" id="">
                                    <input class="w90" style="margin-bottom: 5px;" type="text" placeholder="TEXTO" name="" id="">
                                    <input class="w90" type="text" placeholder="AQUI" name="" id="">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="personalizador__div">
                    <form class="personalizador__form">
                        <div class="form__container" >
                            <div class="div__input w100 ">
                                <label for="">Material y tamaño (Si afecta el precio)</label>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">Autoadhesivo</option>
                                        <option value="">Plástico PAI 2 mm (INTERIOR)</option>
                                        <option value="">Aluminio Compuesto 3 mm (INTEMPERIE)</option>
                                        <option value="">Magnetico (Lámina imantada)</option>
                                    </select>
                                </div>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">8 cm de alto</option>
                                        <option value="">10 cm de alto</option>
                                        <option value="">12 cm de alto</option>
                                        <option value="">14 cm de alto</option>
                                        <option value="">15 cm de alto</option>
                                        <option value="">16 cm de alto</option>
                                        <option value="">18 cm de alto</option>
                                        <option value="">20 cm de alto</option>
                                        <option value="">24 cm de alto</option>
                                        <option value="">28 cm de alto</option>
                                        <option value="">32 cm de alto</option>
                                        <option value="">36 cm de alto</option>
                                        <option value="">40 cm de alto</option>
                                        <option value="">44 cm de alto</option>
                                        <option value="">46 cm de alto</option>
                                        <option value="">50 cm de alto</option>
                                    </select>
                                </div>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">8 cm de ancho</option>
                                        <option value="">10 cm de ancho</option>
                                        <option value="">12 cm de ancho</option>
                                        <option value="">14 cm de ancho</option>
                                        <option value="">15 cm de ancho</option>
                                        <option value="">16 cm de ancho</option>
                                        <option value="">18 cm de ancho</option>
                                        <option value="">20 cm de ancho</option>
                                        <option value="">24 cm de ancho</option>
                                        <option value="">28 cm de ancho</option>
                                        <option value="">32 cm de ancho</option>
                                        <option value="">36 cm de ancho</option>
                                        <option value="">40 cm de ancho</option>
                                        <option value="">44 cm de ancho</option>
                                        <option value="">46 cm de ancho</option>
                                        <option value="">50 cm de ancho</option>
                                    </select>
                                </div>
                                <div class="form__input">
                                    <select class="w90" name="" id="">
                                        <option value="">Normal</option>
                                        <option value="">Reflectante</option>
                                        <option value="">Fotoluminiscente</option>
                                    </select>
                                </div>
                                <label style="font-size: 22px; width: 100%; text-align: center;" for="">$ 18.900 + IVA c/u</label>
                                <label for="">Cantidad</label>
                                <div class="form__input">
                                    <input class="w90" v-model="cantArticulos" type="number">
                                </div>
                                <button class="btn w90" data-color="azul" style="margin-top: var(--gap);color:white">AGREGAR AL CARRO</button>
                                
                                <label for="">Comentarios</label>
                                <div class="form__input">
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="6"></textarea>
                                </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
        </div>
    </div>
     </template>

<script>
export default {
  name: 'Personalizador',
  data(){
    return {
        tabSelected: 1,
        cantArticulos: 1
    }
  },
  mounted(){
        var optSelected = document.getElementById(`tab${this.tabSelected}`);
        optSelected.classList.add('btn-dark')
  },
  methods: {
    changeTab(){
        var optSelected = document.getElementById(`tab${this.tabSelected}`);
        var tab1 = document.getElementById(`tab1`);
        var tab2 = document.getElementById(`tab2`);
        var tab3 = document.getElementById(`tab3`);
        if(this.tabSelected == 1){
            optSelected.classList.add('btn-dark')
            tab2.classList.remove('btn-dark')
            tab3.classList.remove('btn-dark')
        }else if(this.tabSelected == 2){
            optSelected.classList.add('btn-dark')
            tab1.classList.remove('btn-dark')
            tab3.classList.remove('btn-dark')
        }else if(this.tabSelected == 3){
            optSelected.classList.add('btn-dark')
            tab1.classList.remove('btn-dark')
            tab2.classList.remove('btn-dark')
        }
    }
  }
}

</script>

<style>
</style>